import React, { useState } from 'react';
import Layout from '../components/layout';
import styled from 'styled-components';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

const Contact = ({ data }) => {
  const [buttonMessage, setButtonMessage] = useState('Send Message');
  const [showMessage, setShowMessage] = useState(false);

  const StyledContact = styled.div`
    position: relative;
    .top-bg {
      height: 30rem;
      position: relative;
      z-index: 5;
      h1 {
        position: absolute;
        bottom: -5rem;
        left: 50%;
        margin-bottom: 0;
        transform: translate(-50%, -50%);
        color: #ffffff;
        background: ${props => props.theme.colors.org};
        padding: 1.5rem 2.5rem;
        font-size: 2.5rem;
      }
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .container {
      display: flex;
      justify-content: space-between;
      margin: 6rem auto 10rem;
      .form-left {
        width: 50%;
        margin-top: 2.5rem;
        h2 {
          font-size: 2.6rem;
          margin-bottom: 0;
          color: ${props => props.theme.colors.org};
        }
        p {
          margin-top: 0.6rem;
          font-size: 1.6rem;
          font-family: ${props => props.theme.fonts.sec};
          margin-bottom: 3rem;
        }
        form {
          display: flex;
          flex-direction: column;
          input,
          textarea {
            margin-bottom: 1rem;
            padding: 2rem;
            font-size: 1.4rem;
          }
          button {
            border: 0;
            background: transparent;
            border: 2px solid ${props => props.theme.colors.org};
            color: ${props => props.theme.colors.org};
            padding: 1.5rem 0;
            font-size: 1.4rem;
            width: 50%;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              background: ${props => props.theme.colors.org};
              color: #ffffff;
            }
          }
          span {
            margin-top: 1rem;
            font-size: 1.4rem;
          }
        }
        .text-block {
          margin-bottom: 3.5rem;
          h2 {
            font-size: 2.6rem;
            color: #000000;
          }
          p {
            font-size: 1.6rem;
            font-family: ${props => props.theme.fonts.sec};
            color: #333333;
            line-height: 2rem;
            text-align: justify;
          }
        }
      }
      .text-right {
        width: 45%;
        h2 {
          font-size: 2.6rem;
        }
        p {
          font-size: 1.6rem;
          font-family: ${props => props.theme.fonts.sec};
          color: #333333;
          line-height: 2rem;
          text-align: justify;
        }
        ul {
          list-style: none;
          li {
            margin-bottom: 1rem;
            display: flex;
            flex-wrap: wrap;
          }
          span {
            font-size: 2.2rem;
            margin-bottom: 2rem;
            display: inline-block;
            width: 100%;
            color: ${props => props.theme.colors.org};
          }
          .info {
            width: 45%;
            margin-bottom: 1rem;
          }
          h4 {
            font-size: 1.4rem;
            margin: 0 0 0 0;
          }
          h6 {
            color: ${props => props.theme.colors.grey2};
            font-size: 1.6rem;
            line-height: 2rem;
            width: 90%;
            font-family: ${props => props.theme.fonts.sec};
            margin: 1rem 0;
          }
        }
      }
    }
    @media (max-width: 768px) {
      .top-bg {
        height: 20vh;
      }
      .container {
        flex-direction: column;
        .form-left,
        .text-right {
          width: 100%;
        }
        .text-right {
          margin-top: 3rem;
        }
      }
    }
  `;

  const onSubmit = async e => {
    e.preventDefault();

    setButtonMessage('Sending..');

    const data = new FormData(e.target);

    const url =
      'https://script.google.com/macros/s/AKfycbzjk2Xy0fzLm79llwwOD2et5S0s3ERi2ebe48qIZMsMLDy_LAQN/exec';

    const res = await fetch(url, {
      method: 'POST',
      body: data
    });

    if (res.status === 200) {
      setShowMessage(true);
      setButtonMessage('Send Message');
    }
  };

  return (
    <Layout>
      <SEO title="Contact" />
      <StyledContact>
        <div className="top-bg">
          <a
            href={data.wordpressAcfCompnayDetails.acf.map_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              fluid={
                data.wordpressAcfCompnayDetails.acf.map_photo_contact.localFile
                  .childImageSharp.fluid
              }
            />
          </a>
          <h1>CONTACT</h1>
        </div>
        <div className="container">
          <div className="form-left">
            <form onSubmit={onSubmit}>
              <input type="text" name="name" placeholder="Name" required />
              <input type="email" name="email" placeholder="Email" required />
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                required
              />
              <input
                type="file"
                name="attachment"
                placeholder="Attachment"
                required
              />
              <textarea
                required
                placeholder="Message"
                name="message"
                rows="6"
              ></textarea>
              <button type="submit">{buttonMessage}</button>
              {showMessage && (
                <span>
                  Message was successfully sent, We'll reply as soon as
                  possible.
                </span>
              )}
            </form>
          </div>
          <div className="text-right">
            <div className="text-block">
              <h2>Our Contact Info</h2>
              <ul>
                <li>
                  <span>Address.</span>
                  <div className="info">
                    <h4>KSA ( Main Address )</h4>
                    <h6>{data.wordpressAcfCompnayDetails.acf.address}</h6>
                  </div>
                  <div className="info">
                    <h4>Egypt ( Cairo )</h4>
                    <h6>{data.wordpressAcfCompnayDetails.acf.egypt_address}</h6>
                  </div>
                </li>
                <li>
                  <span>Tel.</span>
                  <div className="info">
                    <h4>KSA ( Main Address )</h4>
                    <h6>{data.wordpressAcfCompnayDetails.acf.phone_number}</h6>
                  </div>
                  <div className="info">
                    <h4>ُEgypt ( Cairo )</h4>
                    <h6>
                      {data.wordpressAcfCompnayDetails.acf.egypt_phone_number}
                    </h6>
                  </div>
                </li>
                <li>
                  <span>Email.</span>
                  <div className="info">
                    <h4>Email</h4>
                    <h6>{data.wordpressAcfCompnayDetails.acf.company_email}</h6>
                  </div>
                  <div className="info">
                    <h4>Email #2</h4>
                    <h6>
                      {data.wordpressAcfCompnayDetails.acf.company_email_2}
                    </h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </StyledContact>
    </Layout>
  );
};

export const data = graphql`
  {
    wordpressAcfCompnayDetails {
      acf {
        need_help_text
        phone_number
        egypt_phone_number
        egypt_address
        address
        egypt_address
        company_email
        company_email_2
        map_link
        map_photo_contact {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default Contact;
